import classNames from 'classnames/bind';
import React, { useRef, useEffect } from 'react';
import { RiArrowRightLine, RiCloseLine } from 'react-icons/ri';

import styles from './ConfirmSlider.module.scss';

const cx = classNames.bind(styles);

const ConfirmSlider = ({ onConfirm, onCancel }) => {
  const trackRef = useRef();
  const ballRef = useRef();
  const isDragging = useRef(false);
  const left = useRef(0);
  const maxX = useRef(0);
  const startX = useRef(0);

  useEffect(() => {
    maxX.current = trackRef.current.clientWidth - (72 - 4);
  }, [onConfirm]);

  const setLeft = (nextLeft) => {
    left.current = nextLeft;
    ballRef.current.style.left = nextLeft + 'px';
  };

  const startDrag = (e) => {
    isDragging.current = true;

    // 마우스 드래그 시작 시
    if (e.type === 'mousedown') {
      startX.current = e.clientX;
      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', endDrag);
    }
    // 터치 드래그 시작 시
    else {
      startX.current = e.touches[0].clientX;
      document.addEventListener('touchmove', onDrag);
      document.addEventListener('touchend', endDrag);
    }
  };

  const onDrag = (e) => {
    if (!isDragging.current) return;

    const clientX = e.clientX ?? e.touches[0].clientX;
    setLeft(Math.min(Math.max(0, clientX - startX.current), maxX.current));
  };

  const endDrag = (e) => {
    isDragging.current = false;

    if (left.current > maxX.current * 0.9) {
      onConfirm();
    }

    setLeft(0);

    // 마우스 드래그 종료 시
    if (e.type === 'mouseup') {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', endDrag);
    }
    // 터치 드래그 종료 시
    else {
      document.removeEventListener('touchmove', onDrag);
      document.removeEventListener('touchend', endDrag);
    }
  };

  return (
    <div ref={trackRef} className={cx('container')}>
      <div className={cx('label')}>확인</div>
      <div className={cx('close')} onClick={onCancel}>
        <RiCloseLine size={32} color="white" />
      </div>
      <div ref={ballRef} className={cx('ball')} style={{ left: left.current }} onMouseDown={startDrag} onTouchStart={startDrag}>
        <RiArrowRightLine size={32} color="white" />
      </div>
    </div>
  );
};

export default ConfirmSlider;
