import React, { useState } from 'react';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

import styles from './index.module.scss';
import { ToastService as toast } from '../../libs/Toast';

const cx = classNames.bind(styles);

const Page = () => {
  const [searchParams] = useSearchParams();
  const [thumbUrls, setThumbUrls] = useState([]);

  useMountEffect(() => {
    const id = searchParams.get('sid');
    const phoneNumber = searchParams.get('pn');

    if (id === null || phoneNumber === null) return;

    axios
      .get(`https://api.aerolens.app/mo/services/${id}/images?phoneNumber=${phoneNumber}&type=thumb`)
      .then((response) => response.data)
      .then(({ success, data }) => {
        if (success) {
          setThumbUrls(data);
          return;
        }

        switch (data.code) {
          case -4005: // serviceId: 13, phoneNumber: 01060540203 are not matched
            toast.error('유효한 접근이 아닙니다.');
            break;

          default:
            toast.error('예기치 않은 오류가 발생했습니다.');
            break;
        }
      });
  });

  return (
    <div className={cx('container')}>
      {thumbUrls.map((thumbUrl, index) => {
        const originUrl = thumbUrl.replace('/thumb/', '/origin/');

        return (
          <a
            key={index}
            href={originUrl}
            target="_blank"
            rel="noreferrer"
            className={cx('thumb')}
            style={{ backgroundImage: `url(${thumbUrl})` }}>
            <div />
          </a>
        );
      })}
    </div>
  );
};

export default Page;
