import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import useMountEffect from '@restart/hooks/useMountEffect';
import axios from 'axios';

import styles from './index.module.scss';
import { ToastService as toast } from '../../libs/Toast';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [position, setPosition] = useState({
    latitude: '',
    longitude: '',
  });

  useMountEffect(() => {
    axios
      .get('https://api.aerolens.app/bo/services/gimbal/position')
      .then((response) => response.data)
      .then(({ success, data }) => {
        if (success) {
          setPosition(data);
        }
      });
  });

  const handleChange = (e) => {
    setPosition({
      ...position,
      [e.target.name]: e.target.value,
    });
  };

  const doSave = () => {
    // 처리 중인 경우
    if (isProcessing) return;
    // 처리 중 상태 정의
    setIsProcessing(true);

    axios
      .post('https://api.aerolens.app/bo/services/gimbal/position', position)
      .then((response) => response.data)
      .then(({ success }) => {
        if (success) {
          toast.success('위도, 경도가 정상적으로 변경되었습니다.');
          setTimeout(moveToBack, 1000);
          return;
        }

        toast.error('예기치 않은 오류가 발생했습니다.');
        setIsProcessing(false);
      });
  };

  const moveToBack = () => {
    navigate('/admin', { state: { auth: true } });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('message')}>촬영할 위치의 위도, 경도를 입력하세요.</div>
      <div className={cx('fields')}>
        <div className={cx('label')}>위도</div>
        <input name="latitude" type="number" value={position.latitude} pattern="[0-9]*" onChange={handleChange} />
        <div className={cx('label')}>경도</div>
        <input name="longitude" type="number" value={position.longitude} pattern="[0-9]*" onChange={handleChange} />
      </div>
      <div className={cx('buttons')}>
        <button type="button" className={cx('cancel')} onClick={moveToBack}>
          취소
        </button>
        <button type="button" onClick={doSave}>
          확인
        </button>
      </div>
    </div>
  );
};

export default Page;
