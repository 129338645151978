import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ToastContainer } from './libs/Toast';
import Intro from './pages/intro';
import Shoot from './pages/shoot';
import Album from './pages/album';
import Auth from './pages/auth';
import Admin from './pages/admin';
import Settings from './pages/settings';

const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer autoClose={2000} />
      <Routes>
        <Route path="/" element={<Intro />} />
        <Route path="/shoot" element={<Shoot />} />
        <Route path="/album" element={<Album />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
