import React, { useState, useRef } from 'react';
import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

import styles from './index.module.scss';
import { ToastService as toast } from '../../libs/Toast';
import sleep from '../../utils/sleep';
import ConfirmSlider from './ConfirmSlider';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const confirmCallback = useRef();

  useMountEffect(() => {
    // state 존재하지 않는 경우; 본 페이지 직접 접근 시
    if (!state) {
      navigate('/auth', { replace: true });
    }
  });

  const confirmStart = () => {
    confirmCallback.current = doStart;
    setIsShowConfirm(true);
  };

  const confirmStop = () => {
    confirmCallback.current = doStop;
    setIsShowConfirm(true);
  };

  const moveToSettings = () => {
    navigate('/settings');
  };

  const doConfirm = () => {
    confirmCallback.current();
    setIsShowConfirm(false);
  };

  const doCancel = () => {
    confirmCallback.current = null;
    setIsShowConfirm(false);
  };

  const doStart = () => {
    // 처리 중 상태 정의
    setIsProcessing(true);

    axios
      .post('https://api.aerolens.app/bo/services/start')
      .then((response) => response.data)
      .then(async ({ success, data }) => {
        // 1s 지연
        await sleep(1000);

        if (success) {
          toast.success('서비스가 정상적으로 시작되었습니다.');
          return;
        }

        switch (data.code) {
          case -4101: // no active service
            toast.error('접수된 서비스 요청이 없습니다.');
            break;

          case -4102: // drone arm failed
            toast.error('ARM 명령을 실패했습니다.');
            break;

          default:
            toast.error('예기치 않은 오류가 발생했습니다.');
            break;
        }
      })
      .finally(() => setIsProcessing(false));
  };

  const doStop = () => {
    // 처리 중 상태 정의
    setIsProcessing(true);

    axios
      .post('https://api.aerolens.app/bo/services/stop')
      .then((response) => response.data)
      .then(async ({ success, data }) => {
        // 1s 지연
        await sleep(1000);

        if (success) {
          toast.success('서비스가 정상적으로 종료되었습니다.');
          return;
        }

        switch (data.code) {
          case -4103: // failed to return to launch
            toast.error('RTL 명령을 실패했습니다.');
            break;

          default:
            toast.error('예기치 않은 오류가 발생했습니다.');
            break;
        }
      })
      .finally(() => setIsProcessing(false));
  };

  const doLogout = () => {
    navigate('/auth', { replace: true });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('buttons')}>
        <button type="button" onClick={confirmStart}>
          시작
        </button>
        <button type="button" onClick={confirmStop}>
          종료
        </button>
        <button type="button" onClick={moveToSettings}>
          설정
        </button>
        <button type="button" className={cx('logout')} onClick={doLogout}>
          로그아웃
        </button>
      </div>
      <img src={require('../../assets/images/logo-horizontal.png')} alt="AeroLens" />
      {isShowConfirm && (
        <div className={cx('curtain')}>
          <ConfirmSlider onConfirm={doConfirm} onCancel={doCancel} />
        </div>
      )}
      {isProcessing && (
        <div className={cx('curtain')}>
          <img src={require('../../assets/images/shutter.png')} alt="Loading" />
        </div>
      )}
    </div>
  );
};

export default Page;
