import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { RiCheckLine } from 'react-icons/ri';
import axios from 'axios';

import styles from './index.module.scss';
import { ToastService as toast } from '../../libs/Toast';
import Agreement from './Agreement';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [isShowAgreement, setIsShowAgreement] = useState(false);

  const handleChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const toggleCheck = () => {
    setIsAgreed(!isAgreed);
  };

  const toggleAgreement = () => {
    setIsShowAgreement(!isShowAgreement);
  };

  const moveToShoot = () => {
    if (phoneNumber.trim() === '') {
      toast.error('전화번호를 입력해주세요.');
      return;
    }
    if (!isAgreed) {
      toast.error('개인정보처리방침에 동의해주세요.');
      return;
    }

    // 처리 중인 경우
    if (isProcessing) return;
    // 처리 중 상태 정의
    setIsProcessing(true);

    axios
      .post('https://api.aerolens.app/mo/services', { phoneNumber })
      .then((response) => response.data)
      .then(({ success, data }) => {
        if (success) {
          const state = { id: data.id, phoneNumber };
          navigate('/shoot', { replace: true, state });
          return;
        }

        switch (data.code) {
          case -4001: // unavailable service now
            toast.error('이용 가능한 드론이 없습니다.');
            break;

          case -4002: // failed to set upload path
          default:
            toast.error('예기치 않은 오류가 발생했습니다.');
            break;
        }
        setIsProcessing(false);
      });
  };

  return (
    <div className={cx('container')}>
      <img src={require('../../assets/images/logo-vertical.png')} alt="AeroLens" />
      <div className={cx('message')}>사진을 받으실 전화번호를 입력하세요.</div>
      <input type="tel" value={phoneNumber} pattern="[0-9]*" maxLength={11} onChange={handleChange} />
      <div className={cx('agree')}>
        <div className={cx(['box', { checked: isAgreed }])} onClick={toggleCheck}>
          <RiCheckLine size={24} color="white" />
        </div>
        <div className={cx('text')}>
          <span onClick={toggleAgreement}>개인정보처리방침</span>에 동의합니다.
        </div>
      </div>
      <button type="button" onClick={moveToShoot}>
        시작하기
      </button>
      {isShowAgreement && <Agreement onClose={toggleAgreement} />}
    </div>
  );
};

export default Page;
