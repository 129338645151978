import React, { useState } from 'react';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import { useNavigate, useLocation } from 'react-router-dom';
import { RiArrowUpLine } from 'react-icons/ri';
import Lottie from 'react-lottie';
import axios from 'axios';

import styles from './index.module.scss';
import { ToastService as toast } from '../../libs/Toast';
import sleep from '../../utils/sleep';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isInService, setIsInService] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useMountEffect(() => {
    // state 존재하지 않는 경우; 본 페이지 직접 접근 시
    if (!state) {
      navigate('/', { replace: true });
    }

    const interval = setInterval(() => {
      axios
        .get(`https://api.aerolens.app/mo/services/${state.id}`)
        .then((response) => response.data)
        .then(({ success, data }) => {
          if (success) {
            switch (data.status) {
              case 'BeforeService':
                break;

              case 'InService':
                setIsInService(true);
                break;

              case 'AfterService':
                navigate(`/album?sid=${state.id}&pn=${state.phoneNumber}`, { replace: true });
                break;

              default:
                break;
            }
          }
        });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const handleGimbal = (direction) => {
    axios
      .post('https://api.aerolens.app/mo/services/gimbal/rotate', { direction })
      .then((response) => response.data)
      .then(({ success, data }) => {
        console.log(success, data);

        if (success) {
          // TODO
          return;
        }

        switch (data.code) {
          default:
            toast.error('예기치 않은 오류가 발생했습니다.');
            break;
        }
      });
  };

  const handleShoot = () => {
    // 처리 중인 경우
    if (isProcessing) return;
    // 처리 중 상태 정의
    setIsProcessing(true);

    axios
      .post(`https://api.aerolens.app/mo/services/${state.id}/capture`)
      .then((response) => response.data)
      .then(async ({ success, data }) => {
        if (success) {
          // 2s 지연
          await sleep(2000);
          return;
        }

        switch (data.code) {
          case -4003: // invalid service id: 11
            toast.error('유효한 서비스가 아닙니다.');
            break;

          case -4004: // service is unavailable
            toast.error('드론 비행 준비 중입니다.');
            break;

          default:
            toast.error('예기치 않은 오류가 발생했습니다.');
            break;
        }
      })
      .finally(() => setIsProcessing(false));
  };

  return (
    <div className={cx('container')}>
      {!isInService && (
        <div className={cx('wrapper')}>
          <div className={cx('message')}>드론 비행 준비 중입니다.</div>
          <div className={cx('lottie')}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: require('../../assets/lottie/anim.json'),
                rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
              }}
              width={654 / 2.4}
              height={710 / 2.4}
            />
          </div>
        </div>
      )}
      {isInService && (
        <>
          <div className={cx('screen')}>
            <iframe
              src="https://kurento.themobilityone.com/viewer.html?robotId=4556c8c0-0b26-4b1e-bb44-aff6b2f65dad"
              title="AeroLens"
              className={cx('viewFinder')}
            />
            <div className={cx(['button', 'up'])} onClick={() => handleGimbal('up')}>
              <RiArrowUpLine size={24} color="white" />
            </div>
            <div className={cx(['button', 'right'])} onClick={() => handleGimbal('right')}>
              <RiArrowUpLine size={24} color="white" />
            </div>
            <div className={cx(['button', 'down'])} onClick={() => handleGimbal('down')}>
              <RiArrowUpLine size={24} color="white" />
            </div>
            <div className={cx(['button', 'left'])} onClick={() => handleGimbal('left')}>
              <RiArrowUpLine size={24} color="white" />
            </div>
          </div>
          <div className={cx('wrapper')}>
            <div className={cx('message')}>아래 버튼을 눌러 사진을 찍으세요.</div>
            <div className={cx(['button', { pressed: isProcessing }])} onClick={handleShoot}>
              <div className={cx('inner1')}>
                <div className={cx('inner2')}>
                  <img src={require('../../assets/images/shutter.png')} alt="Shoot" />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <img src={require('../../assets/images/logo-horizontal.png')} alt="AeroLens" />
    </div>
  );
};

export default Page;
