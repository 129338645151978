import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import styles from './index.module.scss';
import { ToastService as toast } from '../../libs/Toast';

const cx = classNames.bind(styles);

const Page = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const doLogin = () => {
    if (password.trim() === '') {
      toast.error('비밀번호를 입력해주세요.');
      return;
    }
    if (password.trim() !== '1234') {
      setPassword('');
      toast.error('비밀번호를 확인해주세요.');
      return;
    }

    navigate('/admin', { replace: true, state: { auth: true } });
  };

  return (
    <div className={cx('container')}>
      <img src={require('../../assets/images/logo-vertical.png')} alt="AeroLens" />
      <div className={cx('message')}>관리자 비밀번호를 입력하세요.</div>
      <input type="number" value={password} pattern="[0-9]*" onChange={handleChange} />
      <button type="button" onClick={doLogin}>
        로그인
      </button>
    </div>
  );
};

export default Page;
