import React from 'react';
import classNames from 'classnames/bind';
import { RiCloseLine } from 'react-icons/ri';

import styles from './Agreement.module.scss';

const cx = classNames.bind(styles);

const Agreement = ({ onClose }) => {
  return (
    <div className={cx('container')}>
      <div className={cx('close')} onClick={onClose}>
        <RiCloseLine size={32} />
      </div>
      <h1>개인정보처리방침</h1>
      <p>
        (주)모빌리티원('AeroLens'이하 'AeroLens')은 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 원활한 서비스를
        제공하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
      </p>
      <h2>제1조 (개인정보의 처리 목적)</h2>
      <p>
        (주)모빌리티원('AeroLens'이하 'AeroLens')은 서비스 제공 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 목적 이외의
        용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할
        예정입니다.
      </p>
      <h2>제2조 (개인정보의 처리 및 보유 기간)</h2>
      <p>
        (주)모빌리티원은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의일로부터(1개월)까지 위 이용목적을
        위하여 보유·이용됩니다.
      </p>
      <h2>제3조 (처리하는 개인정보의 항목)</h2>
      <p>(주)모빌리티원은 휴대번호번호를 개인정보로 처리하고 있습니다.</p>
      <h2>제4조 (개인정보의 파기절차 및 파기방법)</h2>
      <p>
        (주)모빌리티원은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
        파기 사유가 발생한 개인정보를 선정하고, (주)모빌리티원의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. 전자적 파일 형태의
        정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
      </p>
      <h2>제5조 (정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)</h2>
      <ul>
        <li>① 정보주체는 (주)모빌리티원에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</li>
        <li>
          ② 제1항에 따른 권리 행사는(주)모빌리티원에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을
          통하여 하실 수 있으며 (주)모빌리티원은 이에 대해 지체 없이 조치하겠습니다.
        </li>
        <li>
          ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리
          방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
        </li>
        <li>
          ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
        </li>
        <li>
          ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
        </li>
        <li>
          ⑥ (주)모빌리티원은 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
          대리인인지를 확인합니다.
        </li>
      </ul>
      <h2>제6조 (개인정보의 안전성 확보조치에 관한 사항)</h2>
      <ul>
        <li>
          1. 개인정보 취급 직원의 최소화 및 교육
          <br />
          개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
        </li>
        <li>
          2. 정기적인 자체 감사 실시
          <br />
          개인정보 취급 관련 안정성 확보를 위해 정기적(연 1회)으로 자체 감사를 실시하고 있습니다.
        </li>
        <li>
          3. 개인정보에 대한 접근 제한
          <br />
          개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를
          하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
        </li>
        <li>
          4. 접속기록의 보관 및 위변조 방지
          <br />
          개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나,
          고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다. 또한, 접속기록이 위변조 및 도난, 분실되지 않도록
          보안기능을 사용하고 있습니다.
        </li>
        <li>
          5. 개인정보의 암호화
          <br />
          이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를
          암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
        </li>
        <li>
          6. 해킹 등에 대비한 기술적 대책
          <br />
          (주)모빌리티원('AeroLens')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고
          주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
        </li>
        <li>
          7. 비인가자에 대한 출입 통제
          <br />
          개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
        </li>
      </ul>
      <h2>제7조 (개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)</h2>
      <p>(주)모빌리티원은 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.</p>
      <h2>제8조 (개인정보에 관한 민원서비스)</h2>
      <p>
        귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수
        있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
      </p>
      <h2>개인정보관리책임자</h2>
      <ul>
        <li>- 성명 : 류현욱</li>
        <li>- 소속 : 기업부설연구소</li>
        <li>- 전화 : 070-8220-8556</li>
        <li>- 직위 : 대표이사/CEO</li>
        <li>- 이메일 : support@themobilityone.com</li>
      </ul>
      <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
      <ul>
        <li>
          1. 개인정보침해신고센터 <small>(www.118.or.kr / 국번없이 118)</small>
        </li>
        <li>
          2. 대검찰청 인터넷범죄수사센터 <small>(http://icic.sppo.go.kr/02-3480-3571)</small>
        </li>
        <li>
          3. 경찰청 사이버테러대응센터 <small>(www.ctrc.go.kr/1566-0112)</small>
        </li>
      </ul>
      <p>이 개인정보처리방침은 2024년 9월 1부터 적용됩니다.</p>
      <br />
    </div>
  );
};

export default Agreement;
