import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState, useRef } from 'react';

import Toast from './Toast';
import styles from './ToastContainer.module.scss';
import { setHandler } from './ToastService';

const cx = classNames.bind(styles);

const ToastContainer = ({ autoClose = 1000 }) => {
  const toasts = useRef([]);
  const [, setLastUpdated] = useState();

  useMountEffect(() => {
    setHandler((type, content) => {
      toasts.current = [...toasts.current, { type, content }];
      setLastUpdated(Date.now());

      setTimeout(() => {
        toasts.current.shift();
        setLastUpdated(Date.now());
      }, autoClose);
    });
  });

  return (
    <div className={cx('container')}>
      {toasts.current.map((toast, index) => (
        <Toast key={index} data={toast} />
      ))}
    </div>
  );
};

export default ToastContainer;
